import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'rc-pagination';
import classNames from 'classnames';
import { Row } from 'sana/elements';
import { getDefaultPaginationSettings } from '../../utils/constants';
import styles from './Paging.module.scss';
import './Paging.scss';

const Paging = ({ onPagingChange, pageInfo, total, current }) => {
  const [pagingSetting, setPagingSetting] = useState(
    getDefaultPaginationSettings(pageInfo),
  );
  const [changed, setChanged] = useState(Math.random());
  const [showPaging, setShowPaging] = useState(false);
  useEffect(() => {
    if (pageInfo.itemPerRow) {
      configPagination();
      setShowPaging(true);
    }
  }, [pageInfo.pageSize, pageInfo.itemPerRow, total, current]);

  const configPagination = () => {
    setPagingSetting(prevSetting => ({
      ...prevSetting,
      defaultPageSize: pageInfo.pageSize,
      total,
      current,
      simple: pageInfo.itemPerRow === 1 ? true : undefined,
    }));
    setChanged(Math.random());
  };

  return (
    <Row>
      <div className={classNames(styles.paginWrapper, 'pagination-wrapper')}>
        {showPaging && (
          <Pagination
            key={changed}
            {...pagingSetting}
            onChange={onPagingChange}
          />
        )}
      </div>
    </Row>
  );
};

Paging.propTypes = {
  pageInfo: PropTypes.object,
  onPagingChange: PropTypes.func,
  total: PropTypes.number,
  current: PropTypes.number,
};

export default React.memo(Paging);
