import React from 'react';
import { Row, Col } from 'sana/elements';
import classNames from 'classnames';
import { Select } from 'sana/forms';
import PropTypes from 'prop-types';
import styles from './ProductSetTabs.module.scss';

const ProductSetTabs = props => {
  const {
    pageInfo,
    productSets,
    onChange,
    contentBlockId,
    selectedProductSet,
  } = props;
  const handelDropChange = productSetId => {
    const sortOption = productSets.find(
      productSet => productSet.productSetId === productSetId,
    ).sortOption;
    onChange(productSetId, sortOption);
  };

  return (
    <Row>
      {pageInfo.itemPerRow === 1 ? (
        <Col md={pageInfo.itemColsize}>
          <div className={styles.dropWrapper}>
            <Select
              id={`list-productsetfilter-getproductset_${contentBlockId}`}
              items={productSets
                .filter(product => product.visible === true)
                .map(product => ({
                  name: product.title,
                  value: product.productSetId,
                }))}
              onChange={e => {
                handelDropChange(e);
              }}
              className={styles.dropContent}
            />
          </div>
        </Col>
      ) : (
        <Col>
          <nav>
            <ul className={styles.tabWrapper}>
              {productSets
                .filter(product => product.visible === true)
                .map(({ productSetId, title, sortOption }, index) => (
                  <li
                    key={index}
                    className={classNames(
                      'product-set-filter-tab-click',
                      styles.tabContent,
                      productSetId === selectedProductSet.id &&
                        index === selectedProductSet.index &&
                        styles.active,
                    )}
                    onClick={e => {
                      onChange(productSetId, sortOption, index);
                    }}
                    id={`list-productsetfilter-getproductset_${index}_${productSetId}_of_${contentBlockId}`}
                  >
                    <div className={styles.title}>{title}</div>
                  </li>
                ))}
            </ul>
          </nav>
        </Col>
      )}
    </Row>
  );
};

ProductSetTabs.propTypes = {
  pageInfo: PropTypes.object,
  productSets: PropTypes.array,
  onChange: PropTypes.func,
  contentBlockId: PropTypes.string,
  selectedProductSet: PropTypes.object,
};

export default React.memo(ProductSetTabs);
