import React from 'react';

export const productMdel = {
  id: undefined,
  url: undefined,
  image: { small: undefined },
  name: undefined,
  title: undefined,
  price: undefined,
};

export const getDefaultProductList = size => {
  const list = [];
  for (let i = 0; i < size; i++) {
    list.push(productMdel);
  }
  return list;
};

export const noOfRows = 3;

export const getPageInfo = (mediaContext, model) => {
  const { xs, sm, md, lg, xl } = mediaContext;
  const isSSR = typeof window === 'undefined';
  const {
    layoutSettings: {
      productsOnExtraLarge,
      productsOnLarge,
      productsOnMedium,
      productsOnSmall,
      productsOnExtraSmall,
    },
  } = model;

  let itemPerRow = null;
  let itemColsize = null;
  if (xl) {
    itemPerRow = productsOnExtraLarge;
    itemColsize = 100 / productsOnExtraLarge;
  } else
  if (lg || isSSR) {
    itemPerRow = productsOnLarge;
    itemColsize = 100 / productsOnLarge;
  } else if (md) {
    itemPerRow = productsOnMedium;
    itemColsize = 100 / productsOnMedium;
  } 
  else if (sm) {
    itemPerRow = productsOnSmall;
    itemColsize = 100 / productsOnSmall;
  }
  else if (xs) {
    itemPerRow = productsOnExtraSmall;
    itemColsize = 100 / productsOnExtraSmall;
  }
  const pageSize = itemPerRow * noOfRows;
  return { pageSize, itemPerRow, itemColsize };
};

export const getDefaultPaginationSettings = ({ pageSize, itemPerRow }) => ({
  defaultPageSize: pageSize,
  className: 'product-set-filter-pagination-click',
  defaultCurrent: 1,
  total: 1,
  showTitle: false,
  showLessItems: true,
  hideOnSinglePage: true,
  nextIcon: paginIcon('next'),
  prevIcon: paginIcon('previous'),
  simple: itemPerRow === 1 ? true : undefined,
});

const paginIcon = type => {
  return (<input type="image" src={'/content/files/content/productsetfilter/' + type + '-icon.png'} alt={type +' icon'} />);
};
