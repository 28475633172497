import { PRODUCT_SET_RECEIVED } from './actions';
import { VIEWER_CHANGED, LANGUAGE_CHANGED } from 'sana/events';

const initialState = {
  blocks: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PRODUCT_SET_RECEIVED:
      if (!payload)
        return {};

      const {
        contentBlockId,
        productSetId,
        index,
        sorting,
        products,
        totalCount,
      } = payload;

      const blocks = state.blocks
        ? state.blocks
        : {};
      let Productsets = blocks[contentBlockId]
        ? blocks[contentBlockId]
        : {};
      let productSet =
        blocks[contentBlockId] &&
          blocks[contentBlockId][productSetId]
          ? blocks[contentBlockId][productSetId]
          : { pages: {}, totalCount };

      productSet = {
        ...productSet,
        pages: { ...productSet.pages, [index]: products },
        totalCount,
        sorting,
      };
      Productsets = { [productSetId]: productSet };

      return {
        ...state,
        blocks: { ...blocks, [contentBlockId]: Productsets },
      };
    /* istanbul ignore next */
    case VIEWER_CHANGED:
      return onViewerChanged(state);
    /* istanbul ignore next */
    case LANGUAGE_CHANGED:
      return onLanguageChanged(state);

    default:
      return state;
  }
  /* istanbul ignore next */
  function onViewerChanged(state) {
    return { ...state, blocks: undefined };
  }
  /* istanbul ignore next */
  function onLanguageChanged(state) {
    if (!state.blocks)
      return state;

    return {
      ...state,
      blocks: Object.entries(state.blocks).reduce((blocks, [key, value]) => {
        blocks[key] = { ...value, expired: true };
        return blocks;
      }, {}),
    };
  }
}