import {
  groupBy,
  map,
  mergeMap,
  switchMap,
  pluck,
  catchError,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { merge } from 'rxjs';
import { PRODUCT_SET_REQUESTED, productSetReceived } from './actions';
import { productsQuery } from './queries';
import { parseSortOption } from 'sana/utils';

export default function (action$, _state$, { api }) {
  const getProductEpic$ = action$.pipe(
    ofType(PRODUCT_SET_REQUESTED),
    groupBy(
      action => action.payload.contentBlockId,
      action => action.payload,
    ),
    mergeMap(group =>
      group.pipe(
        switchMap(({ contentBlockId, productSetId, pagination, sorting }) => {
          let sortOption = [];
          if (sorting)
            sortOption = [parseSortOption(sorting)];

          const variables = {
            id: productSetId,
            options: {
              page: {
                index: pagination.index,
                size: pagination.size,
              },
              sorting: sortOption,
            },
          };
          return api.graphApi(productsQuery, variables).pipe(
            pluck('catalog', 'productSet'),
            map(productSet =>
              productSetReceived(
                contentBlockId,
                productSetId,
                pagination.index,
                sorting,
                getProductSetProducts(productSet),
              ),
            ),
            catchError(e => console.log('API ERROR', e)),
          );
        }),
      ),
    ),
  );

  function getProductSetProducts(productSet) {
    if (productSet)
      return {
        products: productSet.products.products,
        totalCount: productSet.products.totalCount,
      };

    return [];
  }

  return merge(getProductEpic$);
}