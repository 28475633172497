exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductSetFilterContentBlock__ProductSetTabs_drop-wrapper{display:flex;justify-content:center;padding:16px 0 8px;border-bottom:1px solid var(--tables_BorderColor,#e6e6e6);margin-bottom:32px}.ProductSetFilterContentBlock__ProductSetTabs_drop-wrapper .ProductSetFilterContentBlock__ProductSetTabs_drop-content{width:100%!important;max-width:214px!important}.ProductSetFilterContentBlock__ProductSetTabs_tab-wrapper{display:flex;flex-direction:row;list-style-type:none;padding:0;border-bottom:1px solid var(--tables_BorderColor,#e6e6e6);-webkit-margin-after:0;margin-block-end:0;margin-bottom:32px;flex-wrap:wrap;justify-content:center}.ProductSetFilterContentBlock__ProductSetTabs_tab-wrapper .ProductSetFilterContentBlock__ProductSetTabs_tab-content{margin:0 20px;min-height:40px;line-height:40px;display:flex;justify-content:center;align-items:center;cursor:pointer;border-bottom:4px solid transparent}.ProductSetFilterContentBlock__ProductSetTabs_tab-wrapper .ProductSetFilterContentBlock__ProductSetTabs_tab-content.ProductSetFilterContentBlock__ProductSetTabs_active{border-bottom:4px solid var(--tabHeader_Active_BottomBorderColor,#1f7bc9)}.ProductSetFilterContentBlock__ProductSetTabs_tab-wrapper .ProductSetFilterContentBlock__ProductSetTabs_tab-content:first-child{margin-left:0}.ProductSetFilterContentBlock__ProductSetTabs_tab-wrapper .ProductSetFilterContentBlock__ProductSetTabs_tab-content .ProductSetFilterContentBlock__ProductSetTabs_title{font-family:var(--headingText_FontFamily,\"Pathway Gothic One\"),sans-serif;color:var(--headingText_FontColor,#000);font-size:var(--headingText_FontSizeH2,2.3em)}.ProductSetFilterContentBlock__ProductSetTabs_tab-wrapper .ProductSetFilterContentBlock__ProductSetTabs_tab-content .ProductSetFilterContentBlock__ProductSetTabs_title *{color:inherit}", ""]);

// exports
exports.locals = {
	"drop-wrapper": "ProductSetFilterContentBlock__ProductSetTabs_drop-wrapper",
	"dropWrapper": "ProductSetFilterContentBlock__ProductSetTabs_drop-wrapper",
	"drop-content": "ProductSetFilterContentBlock__ProductSetTabs_drop-content",
	"dropContent": "ProductSetFilterContentBlock__ProductSetTabs_drop-content",
	"tab-wrapper": "ProductSetFilterContentBlock__ProductSetTabs_tab-wrapper",
	"tabWrapper": "ProductSetFilterContentBlock__ProductSetTabs_tab-wrapper",
	"tab-content": "ProductSetFilterContentBlock__ProductSetTabs_tab-content",
	"tabContent": "ProductSetFilterContentBlock__ProductSetTabs_tab-content",
	"active": "ProductSetFilterContentBlock__ProductSetTabs_active",
	"title": "ProductSetFilterContentBlock__ProductSetTabs_title"
};