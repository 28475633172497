exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductSetFilterContentBlock__Paging_pagin-wrapper{display:flex;width:100%;justify-content:center}", ""]);

// exports
exports.locals = {
	"pagin-wrapper": "ProductSetFilterContentBlock__Paging_pagin-wrapper",
	"paginWrapper": "ProductSetFilterContentBlock__Paging_pagin-wrapper"
};