exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductSetFilterContentBlock__ProductSet_product-set-filter{display:inherit;height:inherit;justify-content:inherit;flex-direction:inherit}", ""]);

// exports
exports.locals = {
	"product-set-filter": "ProductSetFilterContentBlock__ProductSet_product-set-filter",
	"productSetFilter": "ProductSetFilterContentBlock__ProductSet_product-set-filter"
};