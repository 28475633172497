import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'sana/elements';
import styles from './ProductSet.module.scss';
import 'rc-pagination/assets/index.css';
import classNames from 'classnames';
import ProductItem from '../productItem/ProductItem';
import { connect, shallowEqual, useDispatch } from 'react-redux';
import { requestProductSet } from 'behaviour/actions/';
import {
  useLoadEffect,
  useHasAbilities,
  useMatchMediaContext,
  useProductDefaultImages,
} from 'sana/utils';
import { getDefaultProductList, getPageInfo } from '../../utils/constants';
import ProductSetTabs from '../productSetTabs/ProductSetTabs';
import Paging from './../paging/Paging';
import { AbilityTo } from 'sana/constants';

const ProductSet = ({ model, id, productSets, expired, requestProductSet }) => {
  const [canViewCatalog] = useHasAbilities(AbilityTo.ViewCatalog);
  const ref = useRef(null);
  const { medium: noImage } = useProductDefaultImages();
  const modelRef = useRef();
  const mediaContext = useMatchMediaContext();
  let modelExpired = false;
  if (modelRef.current && !shallowEqual(modelRef.current, model))
    modelExpired = true;

  modelRef.current = model;
  const { animationTypeId } = model;
  const productSetsInModel = model.productSets.sort(
    (a, b) => a.displayIndex - b.displayIndex,
  );
  const dispatch = useDispatch();
  const pageInfo = getPageInfo(mediaContext, model);
  const [currentList, setCurrentList] = useState(
    getDefaultProductList(pageInfo.pageSize),
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [productSetData, setProductSetData] = useState({});
  const [selectedProductSet, setSelectedProductSet] = useState({
    id: productSetsInModel[0].productSetId,
    index: 0,
    sortOption: productSetsInModel[0].sortOption,
  });
  const [prevInfo, setPrevInfo] = useState({
    [selectedProductSet.id]: { pageSize: pageInfo.pageSize },
  });

  useLoadEffect(() => {
    if (!canViewCatalog)
      return;

    if (!resetCurrentList()) {
      setCurrentList(getDefaultProductList(pageInfo.pageSize));
      dispatch(
        requestProductSet(
          id,
          selectedProductSet.id,
          {
            index: currentPage - 1,
            size: pageInfo.pageSize,
          },
          selectedProductSet.sortOption,
        ),
      );
    }
  }, [selectedProductSet, currentPage, pageInfo.itemPerRow, canViewCatalog]);

  useLoadEffect(() => {
    if (
      productSets &&
      productSets[selectedProductSet.id] &&
      productSets[selectedProductSet.id].totalCount
    ) {
      setProductSetData(productSets[selectedProductSet.id]);
    }
  }, [productSets && productSets[selectedProductSet.id]]);

  useLoadEffect(() => {
    if (!productSetsInModel[0] || !productSetsInModel[0].productSetId)
      return;
    setSelectedProductSet({
      id: productSetsInModel[0].productSetId,
      sortOption: productSetsInModel[0].sortOption,
      index: 0,
    });
  }, [expired, modelExpired]);

  useLoadEffect(() => {
    resetCurrentList();
  }, [productSets, productSetData]);

  const resetCurrentList = () => {
    if (
      productSets &&
      productSets[selectedProductSet.id] &&
      productSets[selectedProductSet.id].pages[currentPage - 1]
    ) {
      if (
        prevInfo[selectedProductSet.id] &&
        prevInfo[selectedProductSet.id].pageSize === pageInfo.pageSize &&
        prevInfo[selectedProductSet.id].sorting ===
          selectedProductSet.sortOption
      ) {
        setCurrentList(
          productSets[selectedProductSet.id].pages[currentPage - 1],
        );
        return true;
      } else {
        setPrevInfo({
          [selectedProductSet.id]: {
            pageSize: pageInfo.pageSize,
            sorting: selectedProductSet.sortOption,
          },
        });
        return false;
      }
    } else {
      return false;
    }
  };

  const onPagingChange = current => {
    setCurrentPage(current);
    scrollToTop();
  };

  const scrollToTop = () => {
    const yOffset = -80;
    const top =
      ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo(0, top);
  };

  const handleTabChange = (id, sortOption, index) => {
    setCurrentPage(1);
    setSelectedProductSet({ id, sortOption, index });
  };

  return (
    <>
      {canViewCatalog && (
        <div
          className={classNames(
            'product-set-filter-content-block',
            styles.productSetFilter,
          )}
          ref={ref}
        >
          <Row>
            <Col md={12}>
              <ProductSetTabs
                pageInfo={pageInfo}
                productSets={productSetsInModel}
                onChange={handleTabChange}
                contentBlockId={id}
                selectedProductSet={selectedProductSet}
              />
              <Row>
                {currentList &&
                  currentList.map((product, index) => (
                    <ProductItem
                      key={`${id}_${product.id ? product.id : index}`}
                      product={product}
                      animationTypeId={animationTypeId}
                      pageInfo={pageInfo}
                      noImage={noImage}
                    />
                  ))}
              </Row>
            </Col>
          </Row>
          <Paging
            onPagingChange={onPagingChange}
            pageInfo={pageInfo}
            total={productSetData.totalCount}
            current={currentPage}
          />
        </div>
      )}
    </>
  );
};

ProductSet.propTypes = {
  model: PropTypes.shape({
    animationTypeId: PropTypes.string.isRequired,
    productSets: PropTypes.arrayOf(
      PropTypes.shape({
        productSetId: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        sortOption: PropTypes.string,
      }),
    ),
  }),
  id: PropTypes.string.isRequired,
  productSets: PropTypes.object,
  expired: PropTypes.bool,
  requestProductSet: PropTypes.func.isRequired,
};

const mapStateToProps = ({ blocks }, { id }) => {
  const productSet = blocks && blocks[id];

  if (!productSet)
    return { expired: undefined };

  return {
    productSets: productSet,
    expired: productSet.expired,
  };
};

export default connect(mapStateToProps, { requestProductSet })(ProductSet);
