import React, { useMemo } from 'react';
import styles from './ProductItem.module.scss';
import { Col, Link, ProductPrice, ResponsiveLazyImage } from 'sana/elements';
import PropTypes from 'prop-types';
import { productPage } from 'sana/routes';
import 'animate.css';
import classNames from 'classnames';
import { LazyContent } from '../../utils/lazyLoader';

const ProductItem = ({
  product,
  animationTypeId,
  pageInfo,
  noImage,
}) => {
  const to = useMemo(() => productPage(product.id), [product]);
  const productImage =
    product.image && product.image.medium ? product.image.medium : noImage;
  const itemPerRow = pageInfo.itemPerRow;
  const itemColsize = pageInfo.itemColsize;

  return (
    <>
      {itemColsize && (
        <div
          style={{ width: `${itemColsize}%` }}
          className={styles.itemWrapper}
        >
          <Col>
            <div
              className={classNames(
                styles.itemContent,
                styles[`colWrapper${itemPerRow}`],
              )}
            >
              <div
                className={classNames(
                  styles.imgWrp,
                  'product-set-filter-product-image',
                )}
              >
                <div
                  key={Math.random()}
                  className={classNames(
                    styles.imgContainer,
                    product.id && `animated ${animationTypeId}`,
                  )}
                >
                  <LazyContent wait={product.id} className={styles.skeltoncon}>
                    <div className={styles.imgContainer}>
                      <Link url={product.url} to={to}>
                        <ResponsiveLazyImage
                          src={productImage}
                          alt={product.title}
                          className={classNames(
                            styles.image,
                            productImage === noImage && styles.noImage,
                          )}
                          title={product.title}
                          draggable="false"
                        />
                      </Link>
                    </div>
                  </LazyContent>
                </div>
              </div>
              <div
                className={classNames(
                  'product-set-filter-product-title',
                  styles.productTitle,
                )}
              >
                <LazyContent
                  wait={product.title}
                >
                  <Link url={product.url} to={to}>
                    <h2>{product.title}</h2>
                  </Link>
                </LazyContent>
              </div>
              <div className={styles.productPrice}>
                <LazyContent
                  wait={product.title}
                  className={styles.pricesPlaceholder}
                >
                  <ProductPrice
                    wait={product.price}
                    salesPrice={product.price}
                    basePrice={product.listPrice}
                  />
                </LazyContent>
              </div>
            </div>
          </Col>
        </div>
      )}
    </>
  );
};

ProductItem.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.object,
    price: PropTypes.number,
    title: PropTypes.string,
  }),
  animationTypeId: PropTypes.string,
  pageInfo: PropTypes.object,
};

export default React.memo(ProductItem);
