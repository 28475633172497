export const PRODUCT_SET_REQUESTED = 'PRODUCT_SET_FILTER/PRODUCT_SET_REQUESTED';
export const requestProductSet = (
  contentBlockId,
  productSetId,
  pagination,
  sorting,
) => ({
  type: PRODUCT_SET_REQUESTED,
  payload: { contentBlockId, productSetId, pagination, sorting },
});

export const PRODUCT_SET_RECEIVED = 'PRODUCT_SET_FILTER/PRODUCT_SET_RECEIVED';
export const productSetReceived = (
  contentBlockId,
  productSetId,
  index,
  sorting,
  { products, totalCount },
) => ({
  type: PRODUCT_SET_RECEIVED,
  payload: { contentBlockId, productSetId, index, sorting, products, totalCount },
});
