import React from 'react';
import PropTypes from 'prop-types';
import ProductSet from './productSet/ProductSet';

const ProductSetFilter = props => {
  const { model } = props;
  if (!model) {
    return null;
  }

  return <ProductSet {...props} />;
};

ProductSetFilter.propTypes = {
  model: PropTypes.shape({
    animationTypeId: PropTypes.string.isRequired,
    productSets: PropTypes.arrayOf(
      PropTypes.shape({
        productSetId: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        sortOption: PropTypes.string,
      }),
    ).isRequired,
  }),
};

export default React.memo(ProductSetFilter);
